@import "./colours.scss";

.console-container {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: space-around;
  bottom: 0;
  position: fixed;
  z-index: 10;
  
  .console-tab-container {
    height: 6vh;
    width: 6vh;
    margin: 2vh;
    position: fixed;
    bottom: 0;
    right: 0;
    
    .console-tab {
      height: 6vh;
      width: 6vh;
      background-color: $accent-colour;
      transition: background-color 300ms ease;
      border-radius: 50%;
      cursor: pointer;

      &:hover {
        background-color: lighten($accent-colour, 5%);
      }
      &:active {
        background-color: lighten($accent-colour, 10%);
      }
          
      img {
        transition: opacity 200ms ease-in;
        padding: 10% 0;
        width: 80%;
      }

    }
    .easy-mode-button {
      position: fixed;
      bottom: 2.5vh;
      right: 9vh;
      padding: 10px;
      border-radius: 4px;
      font-family: 'Ubuntu Mono', monospace;
      opacity: 0;
      white-space: nowrap;
      background-color: $accent-colour;
      color: $text-colour;

      transition: background-color 300ms ease;
      cursor: pointer;

      &:hover {
        background-color: lighten($accent-colour, 5%);
      }
      &:active {
        background-color: lighten($accent-colour, 10%);
      }
    }
  }

  .console {
    bottom: 10vh;
    transform-origin: bottom right;
    position: relative;
    
    .console-body {
      display: flex;
      flex-wrap: nowrap;
      width: 90vw;
      height: 7vh;
      padding-left: 20px;
      border-bottom-left-radius: 2vh;
      border-bottom-right-radius: 2vh;
      line-height: 7vh;
      text-align: left;
      background-color: $accent-colour;

      
      .console-text-short {
        color:white;
        font-family: 'Ubuntu Mono', monospace;
        font-size: 3vh;
        display: inline-flex;
      }
      
      .console-input {
        display: inline-flex;
        width: 100%;
        padding-left: 10px;
        
        input {
          position: relative;
          height: 100%;
          width: 100%;
          font-family: 'Ubuntu Mono', monospace;
          font-size: 3vh;
          color: white;
          background-color: rgba(0, 0, 0, 0);
          border: none;
          outline: none;

          &::placeholder {
            opacity: 0.5;
            color: invert($text-colour)
          }
        }
      }
    }
    
    .console-output {
      opacity: 1;
      position: absolute;
      bottom: 7vh;
      width: calc(90vw - 20px);
      height: 75vh;
      padding: 20px;
      border-top-left-radius: 2vh;
      border-top-right-radius: 2vh;
      background-color: $accent-colour;
      
      .output-list-container {
        display: flex;
        flex-flow: column-reverse;
        overflow-y: auto;
        height: 100%;
        
        &::-webkit-scrollbar {
          display: none;
        }

        .output-list {
          display: flex;
          flex-direction: column-reverse;
          text-align: left;
          padding: 0 0;
          list-style-type: none;
          transition: 500ms ease;
          
          .item {
            display: flex;
            flex: 0 0 auto;
            color:white;
            font-family: 'Ubuntu Mono', monospace;
            font-size: 3vh;
            line-height: 3vh;
          }
        }
      }
    }
  }
}

.console-body:hover {
  cursor: text;
}