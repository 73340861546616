@import "./colours.scss";

$font-size: 10vh;

.home-body {
  height: 100%;
  width: 100%;
  position: fixed;
  background-color: black;
  overflow: hidden;
  
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

#space {
  height: 100%;
  width: 100%;
  position: absolute;
}

.title-container {
  position: relative;
  margin: 0 auto;
  cursor: default;

  .headline-title {
    z-index: 10;
    line-height: $font-size;
    font-size: $font-size;
    color: white;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 300;
    position: relative;
    transition: text-shadow 200ms ease-out;

    &::selection {
      color: none;
      background: none;
    }
    
    span {
      z-index: 10;
      font-size: $font-size;
      color: white;
      font-family: 'IBM Plex Sans', sans-serif;
      font-weight: 500;
      position: relative;
      word-break: normal;
      transition: text-shadow 200ms ease-out;

      &::selection {
        color: none;
        background: none;
      }
    }

  }

  .sub-title {
    height: $font-size/4;
    text-align: right;

    font-size: $font-size/4;
    color: white;
    text-shadow: 2px 2px 1px black;
    font-family: 'Ubuntu Mono', monospace;
    margin: 10px;

    &::selection {
      color: none;
      background: none;
    }
  }
}

.warp-button {
  width: 124px;
  height: 44px;
  position: fixed;
  top: 80%;
  left: 50%;
  margin-top: -22px;
  margin-left: -62px;
  
  font-size: 22px;
  font-family: 'IBM Plex Sans', sans-serif;
  line-height: 44px;
  text-align: center;
  text-decoration: none;
  color: $text-colour;
  text-shadow: 0 -1px -1px #0f864a;
  
  background-color: $accent-colour;
  border-radius: 4px;
  box-shadow: 0 4px 0 darken($accent-colour, 10%), 0 5px 5px 1px rgba(0, 0, 0, 0.4);
  
  -webkit-transition: all 0.15s ease-in-out;
  -moz-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  cursor: pointer;
}

.warp-button:hover {
  text-shadow: 0 -1px -1px #119d57;
  background-color: darken($accent-colour, 5%);
  box-shadow: 0 4px 0 darken($accent-colour, 15%), 0 5px 5px 1px rgba(0, 0, 0, 0.4);
}
  
.warp-button:active {
	margin-top: -18px;
	box-shadow: none;
}