@import "./colours.scss";

.hamburger-container {
  margin: 2vh;
  position: fixed;
  right: 0;
  bottom: 0;
  height: 6vh;
  width: 6vh;
  z-index: 100;
  
  
  .lines-container {
    position: fixed;
    height: 6vh;
    width: 6vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $accent-colour;
    border-radius: 50%;
    transition: background-color 300ms ease;
    cursor: pointer;
    
    &:hover {
      background-color: lighten($accent-colour, 5%);
    }
    &:active {
      background-color: lighten($accent-colour, 10%);
    }

    .line {
      opacity: 0.6;
      width: 4vh;
      height: 3px;
      border-radius: 3px;
      background-color: $text-colour;
      position: absolute;
      transition: opacity ease 0.2s;
    }
  }


  .easy-mode-button {
    position: fixed;
    bottom: 2.5vh;
    right: 9vh;
    padding: 10px;
    border-radius: 4px;
    font-family: 'Ubuntu Mono', monospace;
    opacity: 0;
    white-space: nowrap;
    background-color: $accent-colour;
    color: $text-colour;

    transition: background-color 300ms ease;
    cursor: pointer;

    &:hover {
      background-color: lighten($accent-colour, 5%);
    }
    &:active {
      background-color: lighten($accent-colour, 10%);
    }
  }
}

.backdrop-filter {
  position: fixed;
  height: 100vh;
  width: 100vw;
  opacity: 0.95;
  background-color: darken($accent-colour, 20%);
  z-index: 15;
  overflow: hidden;
}

.nav-container {
  height: 100vh;
  width: 100vw;
  z-index: 16;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  
  .nav {
    position: absolute;
    padding: 0;
    list-style: none;
    
    .link {
      color: $text-colour;
      opacity: 0.6;
      padding: 10px;
      margin: 20px 0;
      text-align: center;
      font-family: 'IBM Plex Sans', monospace;
      font-size: 50px;
      font-weight: 700;
      pointer-events: all;

      transition: opacity ease 500ms, transform ease 200ms;
      cursor: pointer;

      &:hover {
        transform: scale(1.1);
        opacity: 1;
      }
    }
  }
}

.body-noscroll {
  overflow: hidden;
}