@import "./colours.scss";

.card-container {
  height: 100%;
  width: 100%;
  perspective: 500px;
  position: relative;
  
  .card {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    padding: 10px;
    transform-style: preserve-3d;
    
    .image-container {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      cursor: pointer;
      
      .title-img {
        width: 100%;
        border-radius: 10px;
        
        svg {
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }
      }
      
      .info-cover {
        position: absolute;
        height: 90%;
        width: 90%;
        transform: translateZ(50px);
        border-radius: 10px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

        .card-title {
          padding: 2px;
          background-color: invert($text-colour);
          color: $text-colour;
          font-family: 'Ubuntu Mono', monospace;
          font-size: 20px;
          text-decoration: none;
          text-transform: capitalize;
        }
        
        .date {
          height: 30px;
          line-height: 30px;
          font-family: 'IBM Plex Sans', sans-serif;
          color: $text-colour;
          text-shadow: 0px 0px 5px rgba(0,0,0,1);
          cursor: default;
        }
      }
    }
  }
}