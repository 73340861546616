@import "./colours.scss";

.project-body {
  width: 100vw;
  min-height: 100vh;
  background-color: $background-colour;

  .back-button {
    height: 6vh;
    width: 6vh;
    margin: 2vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: $accent-colour;
    border-radius: 3vh;
    z-index: 10;

    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 300ms ease;

    cursor: pointer;

    &:hover {
      background-color: lighten($accent-colour, 5%);
    }
    &:active {
      background-color: lighten($accent-colour, 10%);
    }
  }

  .bi-arrow-left-short {
    height: 100%;
    width: 100%;
    filter: invert(1);
  }

  .project-wrapper {
    max-width: 1000px;
    height: 100%;
    margin: 0 auto;
    padding: 30px;
    padding-bottom: 0;

    .project-topbar {
      display: flex;
      align-items: center;
      justify-content: right;

      .project-icon-container {
        height: 30px;
        width: 30px;
        margin-right: 20px;
        padding: 10px;
        border-radius: 50%;
        background-color: $accent-colour;
        transition: box-shadow 300ms ease, background-color 300ms ease;

        cursor: pointer;

        &:hover {
          box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, .1);
          background-color: lighten($accent-colour, 5%);
        }
        &:active {
          background-color: lighten($accent-colour, 10%);
        }

        .project-icon {
          height: 30px;
          width: 30px;
          filter: invert(1);
        }
      }

      .project-spacer {
        height: 30px;
        width: 30px;
        flex-basis: 30px;
      }
    }

    .project-description-title {
      margin-top: 40px;
      color: $text-colour;
      font-size: 20px;
    }

    .project-description {
      color: $text-colour;
      opacity: 0.6;
      font-size: 18px;
    }

    .readme-container {
      background-color: lighten($background-colour, 5);
      box-shadow: 0px 0px 50px 1px rgba(0, 0, 0, .5);
      margin: 40px 0 40px 0;
      padding: 40px;
      border-radius: 10px;
      height: 100%;

      .readme-title {
        font-family: 'Ubuntu Mono', monospace;
        float: right;
        color: rgba($text-colour, 0.5);
      }
  
      .readme-markdown {
        width: 100%;
        color: $text-colour;
        word-break: keep-all;
      }
    }
  }
}

.title-img-project {
  opacity: 1;
  position: absolute;
  z-index: 5;
  transform-style: preserve-3d;
  transform: translateZ(50px);
  clip-path: inset(0px 0px 0px 0px);
}

.project-title {
  position: absolute;
  padding: 2px;
  font-weight: 900;
  background-color: invert($text-colour);
  color: $text-colour;
  text-decoration: none;
  text-transform: capitalize;
  font-family: 'Ubuntu Mono', monospace;
  transform-style: preserve-3d;
  z-index: 6;

  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
}

p {
  font-size: 16px;
}