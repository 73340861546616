@import "./colours.scss";

@media only screen and (max-width: 600px) {
  .social-bar-container {
    position: fixed;
    left: 0;
    bottom: 0;
    display: inline-flex;
    flex-direction: row;
    text-align: center;
    margin-bottom: 2vh;
    
    height: 6vh;
    width: 36vh;
    z-index: 11;

    .icons-container {
      display: inline-flex;
      flex-direction: row;
      text-align: center;
      margin-bottom: 2vh;

      div {
        position: relative;
        padding: 1vh;
        z-index: 10;
        height: 4vh;
        
        &:hover img {
          opacity: 1;
        }
        
        img {
          opacity: 0.8;
          height: 4vh;
          width: 4vh;
          transition: opacity 200ms ease;
        }
      }
    }

    .share-button {
      position: relative;
      padding: 1vh;
      z-index: 10;
      height: 4vh;

      background-color: $accent-colour;
      border-radius: 0 50% 50% 0;
    }
  }
}

@media only screen and (min-width: 600px) {
  .social-bar-container {
    position: fixed;
    left: 0;
    top: 22vh;
    display: inline-flex;
    flex-direction: column;
    text-align: center;

    height: 48vh;
    width: 8vh;
    z-index: 10;

    div {
      $animation-dur: 200ms;
      $timing-func: ease;

      padding: 2vh;
      position: relative;
      width: 4vh;
      height: 4vh;
      z-index: 10;
      transition: width $animation-dur $timing-func,
        box-shadow $animation-dur $timing-func,
        border-radius $animation-dur $timing-func;

      &:hover {
        width: 7vh;
        box-shadow: 0px 0px 22px 1px rgba(0,0,0,0.75);
        z-index: 11;
        border-top-right-radius: 10%;
        border-bottom-right-radius: 10%;
      }

      &:hover img {
        opacity: 1;
      }
      
      img {
        opacity: 0.8;
        height: 4vh;
        width: 4vh;
        transition: opacity $animation-dur $timing-func;
      }
    }
  }
}



.github {
  background-color: rgb(33, 31, 31);
}
.twitter {
  background-color: rgb(29, 161, 242);
}
.stackoverflow {
background-color: rgb(244, 128, 36);
}
.codesandbox {
  background-color: #151515;
}
.codepen {
  background-color: white;
}
.linkedin {
  background-color: rgb(40, 103, 178);
}
.email {
  background-color: rgb(212, 70, 56);
}

.invert {
  filter: invert(1);
}