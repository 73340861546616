@import "./colours.scss";

::-webkit-scrollbar {
  background-color: $background-colour;
  width: 16px;
}
::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 4px solid transparent;
  background-clip: content-box;
  background-color: rgba(220, 220, 220, 100);
}
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(180, 180, 180, 100);
}
::-webkit-scrollbar-thumb:active {
  background-color: rgba(150, 150, 150, 100);
}

body {
  background-color: $background-colour;
  overflow-x: hidden;
  font-family: 'IBM Plex Sans', sans-serif;
}

.nav-button-fake {
  height: 6vh;
  width: 6vh;
  margin: 2vh;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 150;
  background-color: $accent-colour;
  border-radius: 50%;
}

* {
  -webkit-tap-highlight-color: transparent;
}

p {
  color: $text-colour;
  padding: 0;
}

code {
  font-family: 'Ubuntu Mono', monospace;
  background-color: invert(lighten($text-colour, 20%));
  color: lighten($text-colour, 20%);
  border-radius: .25rem;
  padding: .125rem;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                        supported by Chrome, Edge, Opera and Firefox */
}