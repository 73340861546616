@import "./colours.scss";

.projects-body {
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-color: $background-colour;

  .title-container {
    position: relative;
    padding: 10vh;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .title {
      position: relative;
      font-family: 'IBM Plex Sans', sans-serif;
      font-weight: 700;
      font-size: 50px;
      line-height: 40px;
      color: $text-colour;
    }

    .sort-container {
      font-size: 18px;
      margin-top: 40px;
        
      .sort-button {
        height: 1.7rem;
        cursor: pointer;
        background-color: $accent-colour;
        padding: 13px;
        border-radius: 1.7rem;
        transition: background-color 300ms ease;

        &:hover {
          background-color: lighten($accent-colour, 5%);
        }
        &:active {
          background-color: lighten($accent-colour, 10%);
        }
        
        .sort-text {
          color: $text-colour;
          font-weight: 500;
          display: inline;
          font-family: 'IBM Plex Sans', sans-serif;
        }
      }
    }
  }
  
  
  .projects-underlay {
    position: absolute;
    height: 100%;
    width: 100%;
  }
  
  .projects-container {
    position: relative;
    padding-bottom: 40px;
    
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    
    a {
      text-decoration: none;
    }
  }
}